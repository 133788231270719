<template>
  <div>
    <a-card title="工资报表">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >

      <a-row gutter="24">
        <a-col :span="10">
          <a-form-model-item :wrapper-col="{ span: 24 }">
            <a-radio-group v-model="radioValue" button-style="solid" @change="changeRadio" style="width: 100%;">
              <a-radio-button value="0" style="width: 25%;">今日</a-radio-button>
              <a-radio-button value="1" style="width: 25%;">昨天</a-radio-button>
              <a-radio-button value="-6" style="width: 25%;">近7天</a-radio-button>
              <a-radio-button value="-29" style="width: 25%;">近30天</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="changeDate" style="width: 100%;" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="员工类型" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-select v-model="searchForm.employee_type" allowClear style="width: 50%;" @change="changeCategory">
              <a-select-option value="permanent">
                正式员工
              </a-select-option>
              <a-select-option value="temporary">
                临时工
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="出勤"><span class="total">{{ summary.attendance.toFixed(2) }}</span></a-descriptions-item>
        <a-descriptions-item label="缺勤扣除"><span class="total">{{ summary.absence.toFixed(2) }}</span></a-descriptions-item>
        <a-descriptions-item label="总计"><span class="total">{{ summary.profit.toFixed(2)
            }}元</span></a-descriptions-item>
      </a-descriptions>

      <div>
        <!-- <flow-pane v-show="currentTab === 'flow'" :items="currentPageItems" :loading="loading" :pagination="pagination"
            @tableChange="tableChange" :statusItems="statusItems" /> -->

        <a-table :columns="columns" :data-source="currentPageItems" size="small" :pagination="pagination"
          @change="tableChange" :loading="loading">
        </a-table>

      </div>
      <div style="text-align: center; margin-top: 12px;">
        <a-spin :spinning="loading && searchForm.page > 1" />
      </div>
    </a-card>
  </div>
</template>

<script>
// import { productionReportDetialsList } from '@/api/report'
import { EmployeeSalaryDetail } from '@/api/finance'
import NP from 'number-precision'
import moment from 'moment'

export default {
  name: 'SalaryReport',
  
  data() {
    return {
      NP,
      radioValue: '0',
      summary: {
        absence: 0,
        attendance: 0,
        profit: 0,
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          sorter: true,
        },
        {
          title: "员工姓名",
          dataIndex: "employee_name",
        },
        {
          title: "日薪",
          dataIndex: "salary_day",
        },
        {
          title: "实际平均日薪",
          dataIndex: "actual_daily_salary",
          customRender: (value, item, index) => {
            return value.toFixed(2)
          }
        },
        {
          title: "工作日期",
          dataIndex: "work_date",
          sorter: true,
        },
        {
          title: "结算编号",
          dataIndex: "payment_number",
        },
        // {
        //   title: "是否结算",
        //   dataIndex: "is_paid",
        //   customRender: (value, item, index) => {
        //     return value ? "是" : "否";
        //   },
        // },
        {
          title: "类型",
          dataIndex: "work_type",
          customRender: (value, item, index) => {
            return value === "Attendance" ? "出勤" : "缺勤";
          },
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "156px",
        },
      ],
      searchForm: {
        dateRange: [moment().startOf('day'), moment().startOf('day')],
        employee_type: null,
        page: 1,
        page_size: 16
      },
      currentPageItems: [],  // 当前页的数据
      loading: true,
      // categoryItems: [],
      items: [],
      pagination: { current: 1, total: 0, pageSize: 16 },
    };
  },
  // computed: {

  // },
  methods: {
    initailize() {
      this.list();
      
    },
    resetSummary() {
      // 重置 summary 对象的所有属性为 0
      this.summary = {
        absence: 0,
        attendance: 0,
        profit: 0,
      };
    },

    calculateSummary() {
  const result = this.items.reduce((acc, current) => {
    // 判断已支付或者未支付，选取对应金额
    let amount = 0;
    if (current.payment_number) {
      // 已结算，用 actual_daily_salary
      amount = parseFloat(current.actual_daily_salary);
    } else {
      // 未结算，用 salary_day
      amount = parseFloat(current.salary_day);
    }

    // 根据工作类型累加到相应字段
    if (current.work_type === 'Attendance') {
      acc.attendance += amount;
    } else if (current.work_type === 'Absence') {
      acc.absence += amount;
    }
    return acc;
  }, { attendance: 0, absence: 0 });

  console.log('result', result);
  this.summary.absence = result.absence;
  this.summary.attendance = result.attendance;
  this.summary.profit = this.summary.absence - this.summary.attendance;
  console.log(this.summary);
},
    // calculateSummary() {
    //   const result = this.items.reduce((acc, current) => {
    //   const amount = parseFloat(current.salary_day);
    //   if (current.work_type === 'Attendance') {
    //     acc.attendance += amount;
    //   } else if (current.work_type === 'Absence') {
    //     acc.absence += amount;
    //   }
    //   return acc;
    // }, { attendance: 0, absence: 0 });
    // console.log('result',result);
    // this.summary.absence = result.absence;
    // this.summary.attendance = result.attendance;
    // this.summary.profit = this.summary.absence- this.summary.attendance;
    // console.log(this.summary);
    // },

    list() {
      // if (this.searchForm.employee_type == 'all') {
      //   this.searchForm.employee_type = null;
      // }
      let form = {
        start_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[0].format('YYYY-MM-DD') : null,
        end_date: this.searchForm.dateRange.length > 0 ? this.searchForm.dateRange[1].format('YYYY-MM-DD') : null,
        employee_type: this.searchForm.employee_type,
      };
      if (!form.end_date) {
        form.end_date = moment(form.status_date_before).add(1, 'days').format('YYYY-MM-DD');
      }

      this.loading = true;
      // salesReportStatistics(form).then(resp => {
      //   this.total = resp;
      // })

      EmployeeSalaryDetail(form).then(resp => {
        this.items = resp.results;
        this.resetSummary()
        this.calculateSummary()
        this.pagination.total = resp.count;
        this.setPageData(1);  // 设置初始页数据
      }).finally(() => {
        this.loading = false;
      });
    },

    setPageData(page) {
      const pageSize = 16;  // 你可以将这个设置为一个响应式的数据属性
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      this.currentPageItems = this.items.slice(startIndex, endIndex);
      this.pagination.current = page;
    },

    tableChange(pagination, filters, sorter) {
      this.sortKey = sorter.field;
      this.sortOrder = sorter.order || 'asc'; // 'ascend' 或 'descend'，这里假设 Ant Design Vue 的排序标记
      this.sortData();
      this.setPageData(pagination.current);
    },

    sortData() {
    if (!this.sortKey) return; // 如果没有指定排序字段，则不执行排序

    this.items.sort((a, b) => {
      const isAsc = this.sortOrder === 'ascend';
      if (a[this.sortKey] < b[this.sortKey]) return isAsc ? -1 : 1;
      if (a[this.sortKey] > b[this.sortKey]) return isAsc ? 1 : -1;
      return 0;
    });
  },

    search() {
      this.list();
    },
   
    changeRadio(evt) {
      let value = evt.target.value;
      if (value < 0) {
        this.searchForm.dateRange = [moment().startOf('day').add(value, 'day'), moment().startOf('day')]
      } else {
        this.searchForm.dateRange = [moment().startOf('day').subtract(value, 'day'), moment().startOf('day').subtract(value, 'day'),]
      }
      this.search();
    },
    changeDate() {
      this.radioValue = null;
      this.search();
    },
    changeCategory() {
      this.search();
    },
    resetForm() {
      this.radioValue = '0';
      this.searchForm = {
        dateRange: [moment().startOf('day'), moment().startOf('day')],
        category: null,
        page: 1,
        page_size: 16
      };
    },
  },
  mounted() {
    const { start_date, end_date,employee_type } = this.$route.query;
    if (start_date && end_date) {
      this.searchForm.dateRange = [moment(start_date), moment(end_date)];
      this.radioValue = null;
    }
    if(employee_type){
      this.searchForm.employee_type = employee_type;
    }
    this.initailize();
  },
}
</script>

<style scoped>
.total {
  color: #a94442;
}
</style>